<template>
  <div class="animated fadeIn">
    <b-row v-if="$can('read', 'stats')">
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-secondary">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{ requested.length }}</h4>
            <p>Requested</p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-primary">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{ started.length }}</h4>
            <p>Started</p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-warning">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{ completed.length }}</h4>
            <p>Completed</p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-success">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{ accepted.length }}</h4>
            <p>Accepted</p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col sm="8">
          <h4>Intake Requests</h4>
        </b-col>
        <b-col sm="4">
          <b-input-group size="sm">
            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="requests.length > 0">
        <b-col sm="12">
          <b-table :sort-compare="sortCompare" :hover="hover" :filter="filter" @filtered="onFiltered" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="requests" :fields="fields" :current-page="currentPage" :per-page="perPage" @row-clicked="rowClicked">
            <template v-slot:head(actions)></template>
            <template v-slot:cell(createdAt)="data">
              <date :value="data.value" format="MM/DD/YYYY"></date>
            </template>
            <template v-slot:cell(status)="data">
              <b-badge :variant="data.value.style">{{ data.value.name }}</b-badge>
            </template>
            <template v-slot:cell(createdBy)="data">
              {{ data.value ? `${data.value.contact.firstName} ${data.value.contact.lastName}` : '--' }}
            </template>
            <template v-slot:cell(actions)="data">
              <router-link :to="{ name: 'Intake', params: { token: data.item.token }}" target="_blank" v-b-tooltip.hover title="View Intake Form"><i class="fa fa-eye"></i></router-link>
              <i v-if="data.item.statusId <= 2" class="fa fa-envelope-o ml-2" target="_blank" v-b-tooltip.hover title="Resend Intake Form"></i>
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
          </nav>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Date from '@/components/text/Date'
import moment from 'moment'

export default {
  name: 'dashboard',
  components: {
    Date
  },
  props: {
    caption: {
      type: String,
      default: 'Clients'
    },
    hover: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      filter: null,
      pendingRequests: [],
      acceptedRequests: [],
      fields: [
        { key: 'client.primaryContact.firstName', label: 'First Name', sortable: true },
        { key: 'client.primaryContact.lastName', label: 'Last Name', sortable: true },
        { key: 'client.primaryContact.emailAddress.address', label: 'E-Mail Address', sortable: true },
        { key: 'createdBy', label: 'Agent', sortable: true },
        { key: 'createdAt', label: 'Requested On', class: 'text-right', sortable: true },
        { key: 'status', label: 'Status', class: 'text-right', sortable: true },
        { key: 'actions', class: "text-right" }
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0
    }
  },
  computed: {
    requested () {
      return this.pendingRequests.filter(o => o.status.name === 'Requested')
    },
    started () {
      return this.pendingRequests.filter(o => o.status.name === 'Started')
    },
    completed () {
      return this.pendingRequests.filter(o => o.status.name === 'Completed')
    },
    accepted () {
      return this.acceptedRequests.filter(o => o.status.name === 'Accepted')
    },
    requests () {
      let allRequests = []
      this.pendingRequests.forEach((request) => {
        allRequests.push(request)
      })
      this.acceptedRequests.forEach((request) => {
        allRequests.push(request)
      })

      allRequests = allRequests.sort((a, b) => {
        if (moment(a.id) > moment(b.id)) { return -1 }
        else if (moment(a.id) < moment(b.id)) { return 1 }
        else { return 0 }
      })
      return allRequests
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    sortCompare (a, b, key) {
      if (key === 'status') {
        if (a[key].sort > b[key].sort) {
          return 1
        } else if (a[key].sort < b[key].sort) {
          return -1
        } else {
          return 0
        }
      } else {
        return null
      }

    },
    clientIntake (token) {
      return '/clients/' + token + '/register'
    },
    fetchIntakes () {
      this.$api.clients.intakeRequests.active()
        .then((response) => {
          this.pendingRequests = response.data
          this.totalRows = this.requests.length
        })
        .catch(() => {
          this.$snotify.error('Error Fetching Intake Requests')
        })

      this.$api.clients.intakeRequests.recent()
        .then((response) => {
          this.acceptedRequests = response.data
          this.totalRows = this.requests.length
        })
        .catch(() => {
          this.$snotify.error('Error Fetching Intake Requests')
        })
    },
    userLink (id) {
      return `clients/${id.toString()}/info`
    },
    rowClicked (item) {
      const userLink = this.userLink(item.client.id)
      this.$router.push({path: userLink})
    }
  },
  created () {
    this.fetchIntakes()
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
  .table-hover {
    cursor: pointer;
  }
</style>
